import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';

function Banner1({ title, description }) {
  return (
    <div id="banner" className="banner1">
      <Container>
        <h1>{title}</h1>
        <p>{description}</p>
      </Container>
    </div>
  );
}

Banner1.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default Banner1;
