import React from 'react';
import Banner from '../components/Banner1';
import Merge from '../components/ProductsMerge';
import Layout from '../components/Layout';

export default () => (
  <Layout current="products" type="products">
    <Banner
      title="一体化B2B营销云"
      description={`提供数据驱动运营所需的所有工具，构建全渠道客户增长体系，\n从洞察到收益，你想要的都有`}
    />
    <Merge />
  </Layout>
);
