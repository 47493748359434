import React from 'react';
import { Container, Image, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ProductPcBorder from '../images/product-pc-border.svg';
import ProductPcBorder2 from '../images/product-pc-border-2.svg';
import ProductPcBorder3 from '../images/product-pc-border-3.svg';
import ProductPcContent1 from '../images/product-pc-content-1.jpg';
import ProductPcContent2 from '../images/product-pc-content-2.jpg';
import ProductPcContent3 from '../images/product-pc-content-3.jpg';
import ProductPcContent4 from '../images/product-pc-content-4.jpg';
import ProductPcContent5 from '../images/product-pc-content-5.jpg';
import TitleWithBg from './TitleWithBg';

const ProductSection = ({
  bgColor,
  title,
  borderImage = ProductPcBorder,
  contentImage,
  titleBeforeIndex = 1,
  content = [],
  featureList = [],
}) => {
  return (
    <section className={`product-section ${bgColor}`}>
      <Container>
        <h2 className={`title-with-before before-${titleBeforeIndex}`}>
          <TitleWithBg title={title} />
        </h2>
        {content.map((item) => (
          <p className="content-text" key={item.slice(0, 10)}>
            {item}
          </p>
        ))}
        <div className="product-pc-border-wrapper">
          <Image src={borderImage} alt="ProductPcBorder" />
          <Image src={contentImage} className="content-image" alt="ProductPcContent" />
        </div>
        <Row className="product-features-row">
          {featureList.map((fItem, index) => (
            <Col
              lg={6}
              md={12}
              key={fItem.id}
              className={`feature-item feature-${index % 2 ? 'right' : 'eft'}`}
            >
              <div className="feature-inner">
                <p className="title">{fItem.title}</p>
                <p className="content">{fItem.content}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

ProductSection.propTypes = {
  bgColor: PropTypes.string,
  borderImage: PropTypes.string,
  contentImage: PropTypes.string,
  title: PropTypes.string,
  titleBeforeIndex: PropTypes.number,
  content: PropTypes.arrayOf(PropTypes.string),
  featureList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
      id: PropTypes.string,
    })
  ),
};

export default () => {
  return (
    <div id="merge" className="products-merge">
      <ProductSection
        bgColor="white"
        title="CDP 客户数据中台"
        titleBeforeIndex={1}
        borderImage={ProductPcBorder2}
        contentImage={ProductPcContent1}
        content={[
          '不同部门使用不同工具，站在自己的角度对数据进行理解和定义，彼此相互独立，形成物理性&逻辑性的双重数据孤岛，阻碍构建营销闭环和全局管理。',
          'CDP客户数据中台打通全链路客户数据，帮助市场，销售，售后建立统一的客户视图，分析客户行为，客户偏好以及选型意向，助力商机高效转化，提升市场营销ROI。',
        ]}
        featureList={[
          {
            title: '数据集成',
            content: '预集成触点数据追踪，开放API数据接入、清洗、存储',
            id: '1-1',
          },
          {
            title: '客户轨迹',
            content: '文章阅读、资料点击、观看时长等，自动记录高意向客户动态',
            id: '1-2',
          },
          {
            title: '客户画像',
            content: '360°客户画像管理系统，属性、标签、群组、行为事件',
            id: '1-3',
          },
          {
            title: '线索管理',
            content: '线索自动打分、评级等能力，加快线索自动化处理',
            id: '1-4',
          },
        ]}
      />
      <ProductSection
        bgColor="grey"
        title="SCRM社交营销"
        titleBeforeIndex={2}
        borderImage={ProductPcBorder}
        contentImage={ProductPcContent2}
        content={[
          '社交营销不仅对B2C有重要价值，对B2B来说也是不可或缺的，如何从中高效获客？',
          'SCRM社交营销模块基于微信生态，通过微信及企业微信，实现获客 - 转化 - 裂变全场景获得更多精准粉丝。',
        ]}
        featureList={[
          {
            title: '私域引流',
            content: '围绕广告投放、内容营销、会议营销等场景全渠道引流至微信/企业微信',
            id: '2-1',
          },
          {
            title: '企微助手',
            content: '企微私域管理工具，辅助SDR/销售在移动端适时洞察客户意图以及客户管理',
            id: '2-2',
          },
          {
            title: '个性化SOP',
            content: '针对客户当前阶段画像，适时唤醒沉睡客户，高效互动&促进转化',
            id: '2-3',
          },
          {
            title: '分享裂变',
            content: '让现有客户&高意向客户带来更多潜在客户，传播路径清晰可见',
            id: '2-4',
          },
        ]}
      />
      <ProductSection
        bgColor="white"
        title="MA营销自动化"
        titleBeforeIndex={3}
        borderImage={ProductPcBorder3}
        contentImage={ProductPcContent3}
        content={[
          '对短期无意向客户缺少线索持续沟通和培养，仅凭销售一次电话就决定继续跟进还是丢弃，粗放式管理将带来线索高流失率。',
          '通过图形化自动营销设计器，结合不同营销场景和客户标签，精准触达潜在客户，进一步提高线索转化率，个性化 & 有温度地输出更多有效线索。',
        ]}
        featureList={[
          {
            title: '实时营销',
            content: '在线索产生了某个事件动作或线索属性发生变化时，即时对其进行个性化触达',
            id: '3-1',
          },
          {
            title: '周期性营销',
            content: '以某个时间周期为条件，重复循环执行的营销策略',
            id: '3-2',
          },
          {
            title: '单次营销',
            content: '对指定客群进行一次营销触达，执行一次即结束',
            id: '3-3',
          },
          {
            title: '互动触达',
            content: '数据驱动，持续互动，涵盖微信、企微、短信、邮件、视频、直播等互动方式',
            id: '3-4',
          },
        ]}
      />
      <ProductSection
        bgColor="grey"
        title="CMS内容管理"
        titleBeforeIndex={4}
        borderImage={ProductPcBorder}
        contentImage={ProductPcContent4}
        content={[
          '内容形式单一，以获客为目的的内容制作及互动，费时又费力。',
          '从内容生产到流转的一站式管理，运用产品介绍、解决方案、客户案例、白皮书等多种内容类型，无需技术投入，0代码3分钟 一键建立企业多渠道内容矩阵。',
        ]}
        featureList={[
          {
            title: '微官网',
            content: '整合文件、视频、微页面、直播等，实现模块化制作和编辑 ，实现优质内容复用',
            id: '4-1',
          },
          {
            title: '文件管理',
            content: '上传文件并设置来源后，引导潜客留资，可统计访问、查看、下载等事件',
            id: '4-2',
          },
          {
            title: '智能表单',
            content: '灵活设置专属表单，自由创建申请注册、线上报名、问卷调查等各类问卷表单',
            id: '4-3',
          },
          {
            title: '内容分发',
            content: '创意内容灵活派发，高效触达客户或企业内不同角色，提升转化效果',
            id: '4-4',
          },
        ]}
      />
      <ProductSection
        bgColor="white"
        title="EMS活动管理"
        titleBeforeIndex={5}
        borderImage={ProductPcBorder2}
        contentImage={ProductPcContent5}
        content={[
          '线上直播/线下展会，如何全渠道引流，以及后续精准触达？',
          '线上直播、线下活动的全流程管理，全方位了解每一位参会者，提升管理效率，简化活动运营流程，提升参会者数字体验。',
        ]}
        featureList={[
          {
            title: '会前报名',
            content: '从创建活动宣传页到报名设置，再到内外部营销渠道推广，以及参会进展提醒',
            id: '5-1',
          },
          {
            title: '会后跟进',
            content: '从直播未观看用户推送回放到收集参会者反馈，再到高意向线索自动分配',
            id: '5-2',
          },
          {
            title: '会中互动',
            content: '从二维码签到到签到客户关联销售提醒，再到活动自助引导或现场报名等互动',
            id: '5-3',
          },
          {
            title: '数据统计',
            content: '一站式分析活动效果-来源、报名参与度、员工转化量、签到、调研、直播数据等',
            id: '5-4',
          },
        ]}
      />
    </div>
  );
};
